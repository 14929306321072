
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as XLSX from "xlsx";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { AddCandidateModel } from "@/domain/interviewer/add-candidate/model/AddCandidateModel";
import { GetMultipleCandidateTemplateModel } from "@/domain/interviewer/multiple-candidate-template/model/GetMultipleCandidateTemplateModel";
import { environment } from "../../../../../../environment";
import Swal from "sweetalert2";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { JobPostingStatusBadge } from "@/presentation/helper/StatusBadge";
import { formatLocalizedDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);
    const startDate = ref("");
    const endDate = ref("");

    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const templateLink = ref<GetMultipleCandidateTemplateModel>({});

    const file = <any>ref(null);
    const fileInfo = <any>ref(null);
    const validationResult = <any>ref(null);
    const candidateList = ref<AddCandidateModel[]>([]);

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const handleFileChange = (event: Event) => {
      console.log("1");
      const uploadedFile = (event.target as HTMLInputElement).files?.[0];

      if (uploadedFile) {
        file.value = uploadedFile;
        fileInfo.value = {
          name: uploadedFile.name,
          size: uploadedFile.size,
          type: uploadedFile.type,
        };

        const validTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

        if (!validTypes.includes(file.value.type)) {
          validationResult.value = {
            valid: false,
            message: t("validators_error.invalidFileType"),
          };
        } else {
          validationResult.value = {};
          readFile(uploadedFile);
        }
        console.log("3");
      } else {
        file.value = null;
        fileInfo.value = null;
      }
    };

    const readFile = (file: File) => {
      try {
        const reader = new FileReader();
        console.log("2");
        reader.onload = (e) => {
          if (e.target) {
            const data = e.target.result;
            if (data) {
              console.log("4");
              const workbook = XLSX.read(data, { type: "binary" });
              const sheet = workbook.Sheets[workbook.SheetNames[0]];
              const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

              // candidateList.value = jsonData.slice(1).map((row: any) => {
              //   return new AddCandidateModel(
              //     jobPostingId.value, // jobPosting
              //     row[0],
              //     row[1],
              //     row[2]
              //   );
              // });

              candidateList.value = jsonData
                .slice(1)
                .filter((row: any) => row && row[0] && row[1] && row[2])
                .map((row: any) => {
                  return new AddCandidateModel(
                    jobPostingId.value,
                    row[0],
                    row[1],
                    row[2]
                  );
                });
              console.log("5");

              if (candidateList.value.length == 0) {
                validationResult.value = {
                  valid: false,
                  message: t("validators_error.requiredFullXlsx"),
                };
              }
            }
          }
        };
        reader.readAsBinaryString(file);
      } catch (e) {
        console.log("e", e);
      }
    };

    const validateFile = () => {
  if (!file.value) return;

  const validTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
      if (!validTypes.includes(file.value.type)) {
        validationResult.value = {
          valid: false,
          message: t("validators_error.requiredTemplateXlsx"),
        };
      } else if (file.value.size > 5 * 1024 * 1024) {
        validationResult.value = {
          valid: false,
          message: t("validators_error.max5MBFileByte"),
        };
      } else {
        validationResult.value = {
          valid: true,
          message: t("swalMessages.VALIDATED_FILE"),
        };
      }
    };

    const downloadTemplate = () => {
      interviewerController
        .getMultipleCandidateTemplate()
        .then((response) => {
          if (response.isSuccess) {
            templateLink.value.templateUrl = response.getValue().templateUrl;
            const fullUrl =
              environment.baseUploadUrl + templateLink.value.templateUrl;

            const link = document.createElement("a");
            link.href = fullUrl;

            // URL'den dosya adını alırken undefined olmasını engelliyoruz
            const fileName = fullUrl.split("/").pop();
            if (fileName) {
              link.download = fileName;
              link.click();
            } else {
              swalNotification.error(
                t("validators_error.requiredFileName"),
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPostingInterviewCounts = () => {
      const jobPostingInterviewCountModel: InterviewCountModel = {
        jobPostingId: jobPostingId.value,
      };

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: Number(response.getValue().total),
              completed: Number(response.getValue().completed),
              inProgress: Number(response.getValue().inProgress),
              failed: Number(response.getValue().failed),
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts();

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications && jobPostingQualifications.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const uploadingIndex = ref(null);

    // File yükleme fonksiyonu
    const handleFileUpload = (event, index) => {
      const file = event.target.files[0];

      if (file) {
        uploadingIndex.value = index;

        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          candidateList.value[index].cv = undefined;
          uploadingIndex.value = null;
          return;
        }
        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          candidateList.value[index].cv = undefined;
          uploadingIndex.value = null;
          return;
        }

        setTimeout(() => {
          let candidate = candidateList.value[index];

          candidate.cv = file;
          uploadingIndex.value = null;
        }, 1500);
      }
    };

    const removeCandidate = (index) => {
      candidateList.value.splice(index, 1);
    };

    const errors = ref<string[]>([]);

    const submitForm = async () => {
      errors.value = [];
      const emailSet = new Set();

      for (let index = 0; index < candidateList.value.length; index++) {
        const candidate = candidateList.value[index];

        if (candidate.status !== "success") {
          if (!candidate.cv) {
            errors.value.push(
              t("validators_error.addCvCandidate", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else if (candidate.email && !validateEmail(candidate.email)) {
            errors.value.push(
              t("validators_error.addRequiredEmailCandidate", {
                index: index + 1,
              })
            );
            candidateList.value[index].status = "error";
          } else if (candidate.email && emailSet.has(candidate.email)) {
            errors.value.push(
              t("validators_error.existCandidateEmail", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else if (
            !candidate.firstName ||
            !candidate.lastName ||
            !candidate.email
          ) {
            errors.value.push(
              t("validators_error.requiredCandidateInfo", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else {
            emailSet.add(candidate.email);
            try {
              await addCandidate(candidate, index, true);
            } catch (error) {
              console.error("Bir hata oluştu:", error);
            }
          }
        }
      }
    };

    const addCandidate = (candidate, index, multiple) => {
      if (uploadingIndex.value != null) {
        swalNotification.error(
          t("validators_error.pleaseWaitForCV"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      }
      if (!multiple) errors.value = [];

      interviewerController
        .addCandidate(candidate)
        .then((response) => {
          if (response.isSuccess) {
            candidateList.value[index].status = "success"; // Yükleme başarılı
          } else if (response.isFailure) {
            candidateList.value[index].status = "error"; // Yükleme hatalı
            errors.value.push(
              `${index + 1}. sıradaki adayın e-postası zaten kayıtlı.`
            );
          }
          uploadingIndex.value = null;
        })
        .catch((e) => {
          candidateList.value[index].status = "error"; // Yükleme hatalı
          Swal.fire({
            text: "Bir hata oluştu.",
            icon: "error",
            confirmButtonText: "Tamam",
            heightAuto: false,
          });
          uploadingIndex.value = null;
        });
    };

    // E-posta doğrulama fonksiyonu
    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailPattern.test(email);
    };

    const series = computed(() => {
      if (jobPostingInterviewCount.value) {
        const completed = Number(jobPostingInterviewCount.value.completed);
        const inProgress = Number(jobPostingInterviewCount.value.inProgress);
        const failed = Number(jobPostingInterviewCount.value.failed);
        return [completed, inProgress, failed];
      } else {
        return [0, 0, 0];
      }
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });

    const totalInterviews = computed(() =>
      series.value.reduce((a, b) => a + b, 0)
    );

    const goInterviewDetailPage = (interviewId?: number) => {
      if (interviewId) {
        router.push({ path: `/interviewer/interview-detail/` + interviewId });
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      JobPostingStatus,
      templateLink,
      isLoading,
      chartOptions,
      series,
      handleFileUpload,
      uploadingIndex,
      removeCandidate,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      file,
      fileInfo,
      validationResult,
      handleFileChange,
      validateFile,
      candidateList,
      downloadTemplate,
      addCandidate,
      submitForm,
      errors,
      JobPostingStatusBadge,
      jobPostingInterviewCount,
      totalInterviews,
      goInterviewDetailPage,
    };
  },
});
