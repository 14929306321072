<template>
  <div
    v-if="isLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>

  <div v-else class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-12 col-md-6 col-lg-4">
      <div class="card card-flush mb-7">
        <span
          v-if="jobPosting.status"
          :class="JobPostingStatusBadge(jobPosting.status)"
        >
          {{
            jobPosting.status === JobPostingStatus.ACTIVE
              ? $t("common.active")
              : jobPosting.status === JobPostingStatus.PENDING
              ? $t("common.waiting")
              : jobPosting.status === JobPostingStatus.COMPLETED
              ? $t("common.completed")
              : $t("common.inactive")
          }}
        </span>
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>{{ jobPosting.title }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-1">
          <!--begin::Permissions-->

          <div class="fw-bold text-gray-600 mb-3">
            {{ formatLocalizedDate(jobPosting.startDate) }} -
            {{ formatLocalizedDate(jobPosting.endDate) }}
          </div>

          <div class="fw-bold text-gray-600 mb-3">
            <a
              data-bs-toggle="modal"
              :data-bs-target="'#descriptionModal' + jobPosting.id"
              class="
                text-primary text-decoration-underline
                fw-bold
                cursor-pointer
              "
            >
              {{ $t("modals.interview.viewDetail") }}
            </a>
          </div>

          <!--begin::Modal-->
          <div
            class="modal fade"
            :id="'descriptionModal' + jobPosting.id"
            tabindex="-1"
            aria-labelledby="descriptionModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="descriptionModalLabel">
                    {{ $t("modals.interview.jobPostingDesc") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body mh-500px overflow-auto">
                  <div v-html="jobPosting.description"></div>

                  <div class="separator my-4"></div>

                  <div
                    v-for="(
                      qualification, i
                    ) in jobPosting.jobPostingQualifications"
                    :key="i"
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    {{ qualification.text }}
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("common.button.close") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal-->

          <router-link :to="'/interviewer/jobposting-detail/' + jobPosting.id">
            <div class="fw-bold text-gray-600 mb-3">
              {{ $t("modals.interview.candidateCount") }}:
              {{ jobPosting.candidateCount }}
            </div>

            <div class="d-flex flex-column text-gray-600 pb-5">
              <div
                v-for="(qualification, i) in visibleRequirements(
                  jobPosting.jobPostingQualifications
                )"
                :key="i"
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                {{ qualification.text }}
              </div>

              <div
                v-if="
                  jobPosting.jobPostingQualifications &&
                  jobPosting.jobPostingQualifications.length > 3
                "
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                <em
                  >{{
                    hiddenRequirementCount(jobPosting.jobPostingQualifications)
                  }}
                  {{ $t("modals.interview.morePieces") }}</em
                >
              </div>
            </div>
          </router-link>
          <!--end::Permissions-->
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <router-link
              :to="'/interviewer/jobposting-detail/' + jobPosting.id"
              class="flex-grow-1"
            >
              <a class="btn btn-sm btn-primary btn-active-secondary w-100">
                {{ $t("common.button.review") }}
              </a>
            </router-link>
          </div>

          <div
            class="separator my-4"
            v-if="jobPosting.interviews && jobPosting.interviews.length > 0"
          ></div>

          <div
            class="d-flex flex-wrap gap-2 mb-3"
            v-if="jobPosting.interviews && jobPosting.interviews.length > 0"
          >
            <!-- Görünen mülakat öğeleri -->
            <div
              v-for="(item, index) in visibleItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              @click="goInterviewDetailPage(item.id)"
              style="flex: 0 0 auto; min-width: 150px"
            >
              <span class="fs-6 fw-semibold text-muted lh-1"
                >{{ item.title }}
              </span>
            </div>

            <!-- +X butonu -->
            <div
              v-if="hiddenItemCount(jobPosting.interviews) > 0 && !showAll"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; cursor: pointer; min-width: 55px"
              @click="showAllInterviews"
            >
              <span class="fs-6 fw-semibold text-muted lh-1"
                >+{{ hiddenItemCount(jobPosting.interviews) }}</span
              >
            </div>

            <!-- Gizli mülakatlar gösterildiğinde -->
            <div
              v-if="showAll"
              v-for="(item, index) in hiddenItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              @click="goInterviewDetailPage(item.id)"
              style="flex: 0 0 auto; min-width: 150px"
            >
              <span class="fs-6 fw-semibold text-muted lh-1">{{
                item.title
              }}</span>
            </div>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--begin::Contact group wrapper-->
      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>{{ $t("modals.interview.interviewCount") }}</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <apexchart
              v-if="jobPostingInterviewCount.total != 0"
              class="statistics-widget-3-chart card-rounded-bottom"
              type="donut"
              :options="chartOptions"
              :series="series"
            ></apexchart>
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >{{ $t("modals.interview.totalInterviewCount") }}</a
              >
              <div class="badge badge-light-primary">
                {{ jobPostingInterviewCount.total }}
              </div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.completedInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[0] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary">{{
                $t("modals.interview.ongoingInterview")
              }}</a>
              <div class="badge badge-light-primary">{{ series[1] }}</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-danger text-hover-primary">{{
                $t("modals.interview.canceledInterview")
              }}</a>
              <div class="badge badge-light-danger">{{ series[2] }}</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->
    </div>
    <!--end::Contact groups-->

    <!--begin::Content-->
    <div class="col-12 col-md-6 col-lg-8">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin::Contacts-->
        <div class="card card-flush h-lg-100" id="kt_contacts_main">
          <!--begin::Card header-->
          <div class="card-header pt-3" id="kt_chat_contacts_header">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>{{ $t("modals.interview.inviteCandidate") }}</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-5">
            <div
              class="
                notice
                d-flex
                bg-light-warning
                rounded
                border-warning border border-dashed
                mb-10
                p-6
              "
            >
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <div class="fs-6 fw-bold">
                  <p class="mb-2">
                    {{ $t("modals.interview.candidate.downloadTemplateDesc") }}
                  </p>
                </div>
              </div>
              <!--end::Wrapper-->
            </div>

            <!--begin::Bilgilendirme-->
            <!-- <div class="alert alert-warning mb-8">
      <p class="mb-2">
        Çoklu aday ekleme işlemi için önce aşağıdaki şablonu indirip doldurun.
      </p>
      <p>
        Şablonu doldurduktan sonra .xlsx veya .csv formatında tekrar yükleyerek adayları ekleyebilirsiniz.
      </p>
    </div> -->
            <!--end::Bilgilendirme-->

            <!--begin::Şablon İndir-->
            <div class="d-flex justify-content-center align-items-center mb-8">
              <button
                type="button"
                class="btn btn-primary btn-md shadow"
                style="display: inline-flex; align-items: center"
                @click="downloadTemplate"
              >
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/files/fil009.svg" />
                </span>
                {{ $t("modals.interview.candidate.downloadTemplate") }}
              </button>
            </div>
            <!--end::Şablon İndir-->

            <!--begin::Dosya Yükleme-->
            <div class="d-flex align-items-center mb-8">
              <div class="flex-grow-1 me-4">
                <label class="form-label fs-5">{{
                  $t("modals.interview.candidate.uploadCandidateList")
                }}</label>
                <div class="d-flex align-items-center">
                  <input
                    type="file"
                    id="file-input"
                    name="file"
                    class="form-control me-2"
                    accept=".xlsx"
                    @change="handleFileChange"
                  />
                  <!-- <button
        class="btn btn-success"
        :disabled="!fileInfo"
        @click="validateFile"
      >
        Yükle
      </button> -->
                </div>
                <div v-if="fileInfo" class="mt-2 text-muted fs-7">
                  <strong
                    >{{
                      $t("modals.interview.candidate.selectedFile")
                    }}:</strong
                  >
                  {{ fileInfo.name }}<br />
                  <strong
                    >{{ $t("modals.interview.candidate.fileByte") }}:</strong
                  >
                  {{ (fileInfo.size / 1024 / 1024).toFixed(2) }} MB
                </div>
              </div>
            </div>
            <!--end::Dosya Yükleme-->

            <div
              v-if="validationResult && validationResult.message"
              class="mt-4 alert alert-danger"
            >
              <p>
                <strong
                  >{{ $t("modals.interview.candidate.selectedFile") }}:</strong
                >
                {{ validationResult.message }}
              </p>
            </div>

            <!--end::Aday Ekle-->
          </div>
          <!--end::Card body-->
        </div>

        <div class="card card-flush mt-7" v-if="candidateList.length > 0">
          <!--begin::Card header-->
          <div class="card-header pt-7" id="kt_chat_contacts_header">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>{{ $t("modals.interview.candidateList") }}</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-5" v-if="candidateList.length > 0">
            <!--begin::Contact groups-->
            <div class="d-flex flex-column gap-5 mb-7">
              <table class="table text-center mt-3">
                <thead>
                  <tr
                    class="
                      text-start
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                  >
                    <th style="width: 20%">
                      {{ $t("modals.interview.candidate.name") }}
                    </th>
                    <th style="width: 20%">
                      {{ $t("modals.interview.candidate.surname") }}
                    </th>
                    <th style="width: 20%">
                      {{ $t("modals.interview.candidate.email") }}
                    </th>
                    <th style="width: 25%">
                      {{ $t("modals.interview.candidate.cv") }}
                    </th>
                    <th style="width: 15%">{{ $t("common.process") }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(candidate, index) in candidateList"
                    :key="index"
                    :class="{
                      'bg-success-custom': candidate.status === 'success',
                      'bg-error-custom': candidate.status === 'error',
                    }"
                  >
                    <!-- İsim -->
                    <td>
                      <div v-if="candidate.status === 'error'">
                        <input
                          v-model="candidate.firstName"
                          class="
                            ms-3
                            me-3
                            form-control form-control-sm
                            transparent-input
                          "
                          :placeholder="$t('modals.interview.candidate.name')"
                        />
                      </div>
                      <div v-else>
                        {{ candidate.firstName }}
                      </div>
                    </td>

                    <!-- Soyisim -->
                    <td>
                      <div v-if="candidate.status === 'error'">
                        <input
                          v-model="candidate.lastName"
                          class="
                            ms-3
                            me-3
                            form-control form-control-sm
                            transparent-input
                          "
                          :placeholder="
                            $t('modals.interview.candidate.surname')
                          "
                        />
                      </div>
                      <div v-else>
                        {{ candidate.lastName }}
                      </div>
                    </td>

                    <!-- E-posta -->
                    <td>
                      <div v-if="candidate.status === 'error'">
                        <input
                          v-model="candidate.email"
                          class="
                            ms-3
                            me-3
                            form-control form-control-sm
                            transparent-input
                          "
                          :placeholder="$t('modals.interview.candidate.email')"
                        />
                      </div>
                      <div v-else>
                        {{ candidate.email }}
                      </div>
                    </td>
                    <td class="text-center align-middle">
                      <!-- Yüklenen dosyanın adı ve boyutu -->
                      <span v-if="candidate.cv && uploadingIndex !== index">
                        {{ candidate.cv.name }} ({{
                          (candidate.cv.size / (1024 * 1024)).toFixed(2)
                        }}
                        MB)
                      </span>

                      <!-- Dosya yükleme alanı -->
                      <div v-if="!candidate.cv && uploadingIndex !== index">
                        <label
                          :for="'fileInput-' + index"
                          class="btn btn-outline-primary btn-sm custom-hover"
                        >
                          <i class="bi bi-cloud-upload"></i>
                          {{ $t("modals.interview.candidate.uploadCV") }}
                        </label>
                        <Field
                          type="file"
                          name="cv"
                          v-model="candidate.cv"
                          :id="'fileInput-' + index"
                          @change="handleFileUpload($event, index)"
                          accept=".pdf, .doc, .docx"
                          class="form-control-file d-none"
                        />
                      </div>

                      <!-- Yükleme durumu (Yükleniyor kısmı) -->
                      <div v-if="uploadingIndex === index">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">{{
                            $t("common.loading")
                          }}</span>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <!-- Yükleme başarılıysa 'Yüklendi', hata varsa 'Tekrar Dene' -->
                      <div
                        v-if="candidate.status === 'success'"
                        class="text-success"
                      >
                        {{ $t("common.loaded") }}
                      </div>

                      <div
                        v-if="candidate.status === 'error'"
                        class="
                          mt-2
                          d-flex
                          justify-content-center
                          align-items-center
                          gap-2
                        "
                      >
                        <button
                          class="btn btn-sm p-0"
                          @click="addCandidate(candidate, index, false)"
                        >
                          <i class="bi bi-arrow-repeat text-primary fs-1"></i>
                        </button>

                        <button
                          class="btn btn-sm p-0"
                          @click="removeCandidate(index)"
                        >
                          <i class="bi bi-trash text-danger fs-2"></i>
                        </button>
                      </div>

                      <div
                        v-if="
                          candidate.status !== 'success' &&
                          candidate.status !== 'error'
                        "
                        class="
                          d-flex
                          justify-content-center
                          align-items-center
                          gap-2
                          mt-2
                        "
                      >
                        <button
                          class="btn btn-sm p-0"
                          @click="removeCandidate(index)"
                        >
                          <i class="bi bi-trash text-danger fs-2"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="text-gray-600 fw-bold fs-8 mb-10">
                  Sadece pdf, doc ve docx uzantılı dosyalar (maks 2mb)
                </div>
                 -->
            <div v-if="errors.length > 0" class="mt-3 mb-7 alert alert-warning">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </div>

            <div class="d-flex justify-content-center">
              <!--begin::Submit button-->
              <button
                type="button"
                @click="submitForm"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label">{{
                  $t("common.button.sendInvite")
                }}</span>
                <span class="indicator-progress">
                  {{ $t("common.pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Submit button-->
            </div>
          </div>

          <div
            v-else-if="candidateList.length == 0"
            class="card-px text-center"
          >
            <!--begin::Description-->
            <p class="text-gray-900 fs-6 fw-bold my-6">
              <span>{{
                $t("modals.interview.candidate.pleaseAddCandidate")
              }}</span>
            </p>
            <!--end::Description-->
          </div>

          <!--end::Card body-->
        </div>
        <!--end::Contacts-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as XLSX from "xlsx";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { AddCandidateModel } from "@/domain/interviewer/add-candidate/model/AddCandidateModel";
import { GetMultipleCandidateTemplateModel } from "@/domain/interviewer/multiple-candidate-template/model/GetMultipleCandidateTemplateModel";
import { environment } from "../../../../../../environment";
import Swal from "sweetalert2";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { JobPostingStatusBadge } from "@/presentation/helper/StatusBadge";
import { formatLocalizedDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);
    const startDate = ref("");
    const endDate = ref("");

    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const templateLink = ref<GetMultipleCandidateTemplateModel>({});

    const file = <any>ref(null);
    const fileInfo = <any>ref(null);
    const validationResult = <any>ref(null);
    const candidateList = ref<AddCandidateModel[]>([]);

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [
        t("modals.interview.completedInterview"),
        t("modals.interview.ongoingInterview"),
        t("modals.interview.canceledInterview"),
      ],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function () {
                  return total.value > 0 ? `${total.value}` : "0";
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const handleFileChange = (event: Event) => {
      console.log("1");
      const uploadedFile = (event.target as HTMLInputElement).files?.[0];

      if (uploadedFile) {
        file.value = uploadedFile;
        fileInfo.value = {
          name: uploadedFile.name,
          size: uploadedFile.size,
          type: uploadedFile.type,
        };

        const validTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

        if (!validTypes.includes(file.value.type)) {
          validationResult.value = {
            valid: false,
            message: t("validators_error.invalidFileType"),
          };
        } else {
          validationResult.value = {};
          readFile(uploadedFile);
        }
        console.log("3");
      } else {
        file.value = null;
        fileInfo.value = null;
      }
    };

    const readFile = (file: File) => {
      try {
        const reader = new FileReader();
        console.log("2");
        reader.onload = (e) => {
          if (e.target) {
            const data = e.target.result;
            if (data) {
              console.log("4");
              const workbook = XLSX.read(data, { type: "binary" });
              const sheet = workbook.Sheets[workbook.SheetNames[0]];
              const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

              // candidateList.value = jsonData.slice(1).map((row: any) => {
              //   return new AddCandidateModel(
              //     jobPostingId.value, // jobPosting
              //     row[0],
              //     row[1],
              //     row[2]
              //   );
              // });

              candidateList.value = jsonData
                .slice(1)
                .filter((row: any) => row && row[0] && row[1] && row[2])
                .map((row: any) => {
                  return new AddCandidateModel(
                    jobPostingId.value,
                    row[0],
                    row[1],
                    row[2]
                  );
                });
              console.log("5");

              if (candidateList.value.length == 0) {
                validationResult.value = {
                  valid: false,
                  message: t("validators_error.requiredFullXlsx"),
                };
              }
            }
          }
        };
        reader.readAsBinaryString(file);
      } catch (e) {
        console.log("e", e);
      }
    };

    const validateFile = () => {
  if (!file.value) return;

  const validTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
      if (!validTypes.includes(file.value.type)) {
        validationResult.value = {
          valid: false,
          message: t("validators_error.requiredTemplateXlsx"),
        };
      } else if (file.value.size > 5 * 1024 * 1024) {
        validationResult.value = {
          valid: false,
          message: t("validators_error.max5MBFileByte"),
        };
      } else {
        validationResult.value = {
          valid: true,
          message: t("swalMessages.VALIDATED_FILE"),
        };
      }
    };

    const downloadTemplate = () => {
      interviewerController
        .getMultipleCandidateTemplate()
        .then((response) => {
          if (response.isSuccess) {
            templateLink.value.templateUrl = response.getValue().templateUrl;
            const fullUrl =
              environment.baseUploadUrl + templateLink.value.templateUrl;

            const link = document.createElement("a");
            link.href = fullUrl;

            // URL'den dosya adını alırken undefined olmasını engelliyoruz
            const fileName = fullUrl.split("/").pop();
            if (fileName) {
              link.download = fileName;
              link.click();
            } else {
              swalNotification.error(
                t("validators_error.requiredFileName"),
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPostingInterviewCounts = () => {
      const jobPostingInterviewCountModel: InterviewCountModel = {
        jobPostingId: jobPostingId.value,
      };

      interviewerController
        .getInterviewCounts(jobPostingInterviewCountModel)
        .then((response) => {
          if (response.isSuccess) {
            jobPostingInterviewCount.value = {
              total: Number(response.getValue().total),
              completed: Number(response.getValue().completed),
              inProgress: Number(response.getValue().inProgress),
              failed: Number(response.getValue().failed),
            };
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      getJobPostingInterviewCounts();

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications && jobPostingQualifications.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const uploadingIndex = ref(null);

    // File yükleme fonksiyonu
    const handleFileUpload = (event, index) => {
      const file = event.target.files[0];

      if (file) {
        uploadingIndex.value = index;

        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          candidateList.value[index].cv = undefined;
          uploadingIndex.value = null;
          return;
        }
        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          candidateList.value[index].cv = undefined;
          uploadingIndex.value = null;
          return;
        }

        setTimeout(() => {
          let candidate = candidateList.value[index];

          candidate.cv = file;
          uploadingIndex.value = null;
        }, 1500);
      }
    };

    const removeCandidate = (index) => {
      candidateList.value.splice(index, 1);
    };

    const errors = ref<string[]>([]);

    const submitForm = async () => {
      errors.value = [];
      const emailSet = new Set();

      for (let index = 0; index < candidateList.value.length; index++) {
        const candidate = candidateList.value[index];

        if (candidate.status !== "success") {
          if (!candidate.cv) {
            errors.value.push(
              t("validators_error.addCvCandidate", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else if (candidate.email && !validateEmail(candidate.email)) {
            errors.value.push(
              t("validators_error.addRequiredEmailCandidate", {
                index: index + 1,
              })
            );
            candidateList.value[index].status = "error";
          } else if (candidate.email && emailSet.has(candidate.email)) {
            errors.value.push(
              t("validators_error.existCandidateEmail", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else if (
            !candidate.firstName ||
            !candidate.lastName ||
            !candidate.email
          ) {
            errors.value.push(
              t("validators_error.requiredCandidateInfo", { index: index + 1 })
            );
            candidateList.value[index].status = "error";
          } else {
            emailSet.add(candidate.email);
            try {
              await addCandidate(candidate, index, true);
            } catch (error) {
              console.error("Bir hata oluştu:", error);
            }
          }
        }
      }
    };

    const addCandidate = (candidate, index, multiple) => {
      if (uploadingIndex.value != null) {
        swalNotification.error(
          t("validators_error.pleaseWaitForCV"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      }
      if (!multiple) errors.value = [];

      interviewerController
        .addCandidate(candidate)
        .then((response) => {
          if (response.isSuccess) {
            candidateList.value[index].status = "success"; // Yükleme başarılı
          } else if (response.isFailure) {
            candidateList.value[index].status = "error"; // Yükleme hatalı
            errors.value.push(
              `${index + 1}. sıradaki adayın e-postası zaten kayıtlı.`
            );
          }
          uploadingIndex.value = null;
        })
        .catch((e) => {
          candidateList.value[index].status = "error"; // Yükleme hatalı
          Swal.fire({
            text: "Bir hata oluştu.",
            icon: "error",
            confirmButtonText: "Tamam",
            heightAuto: false,
          });
          uploadingIndex.value = null;
        });
    };

    // E-posta doğrulama fonksiyonu
    const validateEmail = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailPattern.test(email);
    };

    const series = computed(() => {
      if (jobPostingInterviewCount.value) {
        const completed = Number(jobPostingInterviewCount.value.completed);
        const inProgress = Number(jobPostingInterviewCount.value.inProgress);
        const failed = Number(jobPostingInterviewCount.value.failed);
        return [completed, inProgress, failed];
      } else {
        return [0, 0, 0];
      }
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => acc + value, 0);
    });

    const totalInterviews = computed(() =>
      series.value.reduce((a, b) => a + b, 0)
    );

    const goInterviewDetailPage = (interviewId?: number) => {
      if (interviewId) {
        router.push({ path: `/interviewer/interview-detail/` + interviewId });
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      formatLocalizedDate,
      JobPostingStatus,
      templateLink,
      isLoading,
      chartOptions,
      series,
      handleFileUpload,
      uploadingIndex,
      removeCandidate,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      visibleRequirements,
      hiddenRequirementCount,
      showAll,
      jobPosting,
      file,
      fileInfo,
      validationResult,
      handleFileChange,
      validateFile,
      candidateList,
      downloadTemplate,
      addCandidate,
      submitForm,
      errors,
      JobPostingStatusBadge,
      jobPostingInterviewCount,
      totalInterviews,
      goInterviewDetailPage,
    };
  },
});
</script>

<style>
.hover-effect {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
  background-color: #f8f9fa; /* Hafif gri renk */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.interview-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.interview-item:hover {
  background-color: #f0f0f0; /* Gri arka plan */
  color: #333; /* Metin rengi */
}

.interview-item.disabled:hover {
  background-color: #e0e0e0; /* Hoverda aynı gri kalır */
  color: #6c757d; /* Metin rengi de aynı kalır */
}

/* Optional: Style the upload button */
label[for="fileInput"] {
  cursor: pointer;
  transition: all 0.3s ease;
}

label[for="fileInput"]:hover {
  background-color: #f0f0f0;
  border-color: #007bff;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.bg-success-custom {
  background-color: #d3fadc; /* Açık yeşil */
  color: #051f0b; /* Yazı rengi */
}

.bg-error-custom {
  background-color: #ffc1c6 !important; /* Açık kırmızı */
  color: #29070b; /* Yazı rengi */
}

.transparent-input {
  background-color: transparent !important; /* Saydam arka plan */
  border: 1px solid #ffffff; /* İnce bir sınır ekleyebilirsiniz */
  padding: 0.2rem 0.5rem; /* Küçük padding ile daha küçük görünüm */
  font-size: 0.85rem; /* Daha küçük font boyutu */
  color: #333; /* Metin rengi */
}

.transparent-input::placeholder {
  color: #888; /* Placeholder rengi */
}

.custom-hover:hover {
  color: white !important; /* Yazı rengini beyaz yapar */
  background-color: #007bff; /* Arka plan rengini değiştirir (opsiyonel) */
}

.d-flex.align-items-center.py-2 {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.card-title h2 {
  word-wrap: break-word;
  overflow-wrap: anywhere;
  white-space: normal;
  max-width: 100%;
}
</style>
